body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* Light theme - keeping existing colors */
  --primary-color: #2563eb;
  --secondary-color: #3b82f6;
  --text-primary: #1f2937;
  --text-secondary: #4b5563;
  --bg-primary: #ffffff;
  --bg-secondary: #f3f4f6;
  --accent-color: #61dafb;

  /* Dark theme - updated with softer colors */
  --dark-primary: #3b82f6;
  --dark-secondary: #60a5fa;
  --dark-text-primary: #e2e8f0;
  --dark-text-secondary: #cbd5e1;
  --dark-bg-primary: #c9cfda;
  /* Changed from #111827 to a softer navy */
  --dark-bg-secondary: #c3c9d3;

  /* Font family */
  --font-primary: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  --font-heading: 'Poppins', var(--font-primary);
}

/* Dark mode class */
.dark-mode {
  --primary-color: var(--dark-primary);
  --secondary-color: var(--dark-secondary);
  --text-primary: var(--dark-text-primary);
  --text-secondary: var(--dark-text-secondary);
  --bg-primary: var(--dark-bg-primary);
  --bg-secondary: var(--dark-bg-secondary);
}