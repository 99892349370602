/* Base Styles */
.header {
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #282c34;
    transition: background-color 0.3s;
    z-index: 1000;
    box-sizing: border-box;
    /* Ensures padding is included in width calculations */
    background: rgba(19, 23, 37, 0.8);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

body {
    background-color: #f5f5f5;
    color: #333;
    transition: background-color 0.3s, color 0.3s;

}

.logo h1 {
    color: white;
    font-size: 24px;
    margin: 0;
}

.nav-links {
    display: flex;
    align-items: center;

}

.nav-links a {
    color: white;
    text-decoration: none;
    margin: 0 15px;
    font-size: 18px;
    font-weight: 500;
    position: relative;
}

.nav-links a:hover {
    text-decoration: underline;
}

.nav-links a::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background: var(--primary-color);
    transition: width 0.3s ease;
}

.nav-links a:hover::after {
    width: 100%;
}

/* Dark mode styles */
body.dark-mode {
    background-color: #1f1c1c;
    color: #e0e0e0;
}

.header.dark {
    background-color: #cec4c4;
    background: rgba(202, 206, 214, 0.8);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.nav-links a.dark {
    /* color: #e0e0e0; */
    color: #1b1919;
}


.dark-mode-toggle {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 18px;
    margin-left: 40px;
}

/* Hamburger Styles */
.hamburger {
    display: none;
    /* Hidden by default */
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
}

.button-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);


    /* Centers the button within this container */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .hamburger {
        display: flex;
        /* Show hamburger icon */
        /* border: 3px solid #61dafb; */
    }


    .nav-links {
        display: none;
        /* Hide navigation links by default */
        flex-direction: column;
        width: 100%;
        text-align: center;
        background-color: #282c34;
        position: absolute;
        top: 52px;
        left: 0;

    }

    .nav-links.show {

        display: flex;
        /* Show nav links when hamburger is clicked */
    }

    .nav-links a {
        margin: 10px 0;

    }

    .dark-mode-toggle {
        /* margin-top: 5px; */
        align-self: center;
    }
}

@media (max-width: 480px) {
    .header {
        padding: 20px;
        align-items: center;
        /* border: 3px solid #61dafb; */

    }


    .logo h1 {
        font-size: 18px;

    }

    .nav-links {
        align-items: center;
        width: 100%;

    }

    .nav-links a {
        margin: 10px 0;
        font-size: 16px;

    }

    .dark-mode-toggle {
        font-size: 16px;
    }
}