.social-links {
    position: fixed;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 15px;
    z-index: 100;
}

.social-link {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.social-link:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    background: #fff;
}

.social-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

/* Dark mode styles */
.social-links.dark .social-link {
    background: #fff;
}

/* Mobile styles */
@media (max-width: 768px) {
    .social-links {
        position: fixed;
        bottom: 20px;
        top: unset;
        right: 50%;
        transform: translateX(50%);
        flex-direction: row;
    }
}