.projects {
    padding: 60px 20px;
    /* background-color: #fff; */
    color: #333;
    text-align: center;
}

.projects h2 {
    font-size: 36px;
    margin-bottom: 40px;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2rem 0;
}

.project-card {
    padding: 20px;
    background: var(--card-bg, #ffffff);
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
}

.project-card h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.project-card p {
    font-size: 16px;
    margin-bottom: 15px;
}

.project-card a {
    text-decoration: none;
    color: #61dafb;
    font-weight: bold;
}

.project-card:hover {
    transform: translateY(-5px);
}

.project-card:hover .project-image img {
    transform: scale(1.05);
}

.project-image {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.project-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.project-content {
    padding: 1.5rem;
}

.tech-stack {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin: 1rem 0;
}

/* .tech-tag {
    background: var(--tag-bg, #f0f0f0);
    color: var(--tag-color, #333);
    padding: 0.25rem 0.75rem;
    border-radius: 15px;
    font-size: 0.875rem;
} */

.tech-tag {
    background: var(--primary-color);
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
    font-size: 0.875rem;
    font-weight: 500;
}

.project-features {
    list-style: none;
    padding-left: 0;
    margin: 1rem 0;
    text-align: left;
}

.project-features li {
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 0.5rem;
    text-align: left;
}

.project-features li::before {
    content: "→";
    position: absolute;
    left: 0;
    color: var(--accent-color, #007bff);
}

.project-links {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
}

.project-links a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    text-decoration: none;
    transition: all 0.3s ease;
}

.github-link {
    background: #24292e;
    color: white;
}

.demo-link {
    background: var(--accent-color, #007bff);
    color: white;
}

.project-links a:hover {
    opacity: 0.9;
    transform: translateY(-2px);
}

/* Dark mode styles */
.projects.dark {
    color: white;
}

.projects.dark .project-card {
    background: rgba(51, 65, 85, 0.8);
    /* Softer dark background */
    border-color: #475569;
    /* Softer border color */
}

.projects.dark .tech-tag {
    background: var(--dark-tag-bg, #3a3a3a);
    color: #fff;
}

/* Add at the end of your existing CSS */

.tools-section {
    margin: 40px 0;
    padding: 20px 0;
}

.tools-section h3 {
    font-size: 24px;
    margin-bottom: 20px;
}

.tools-container {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.tools-scroll {
    display: flex;
    animation: scroll 30s linear infinite;
    white-space: nowrap;
}

.tool-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 30px;
    min-width: 120px;
}

.tool-icon {
    font-size: 2rem;
    margin-bottom: 8px;
    color: #61dafb;
}

.tool-name {
    font-size: 0.9rem;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

/* Dark mode adjustments */
.projects.dark .tool-icon {
    color: #21a1f1;
}

.projects.dark .tool-name {
    color: #fff;
}

/* Hover effect */
.tool-item:hover {
    transform: translateY(-5px);
    transition: transform 0.3s ease;
}

/* Add smooth transition when elements enter/exit */
.tools-container::before,
.tools-container::after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 100px;
    z-index: 2;
}

.tools-container::before {
    background: linear-gradient(to right, var(--bg-primary) 0%, transparent 100%);
}

.tools-container::after {
    background: linear-gradient(to left, var(--bg-primary) 0%, transparent 100%);
}

.projects.dark .tools-container::before {
    background: linear-gradient(to right, var(--dark-bg-primary) 0%, transparent 100%);
}

.projects.dark .tools-container::after {
    background: linear-gradient(to left, var(--dark-bg-primary) 0%, transparent 100%);
}