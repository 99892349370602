.contact {
    padding: 80px 20px;
    background-color: none;
    color: #333;
    text-align: center;
}

.contact h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    position: relative;
    display: inline-block;
}

.contact h2::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: #61dafb;
}

.contact-form {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
}

.form-group {
    display: flex;
    flex-direction: column;
    position: relative;
}

.form-group label {
    margin-bottom: 8px;
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #666;
}

.form-group input,
.form-group textarea {
    padding: 12px 15px;
    font-size: 16px;
    border-radius: 8px;
    border: 2px solid #e0e0e0;
    background-color: rgba(255, 255, 255, 0.9);
    color: #333;
    transition: all 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: #61dafb;
    box-shadow: 0 0 0 2px rgba(97, 218, 251, 0.2);
    outline: none;
}

.form-group textarea {
    resize: vertical;
    min-height: 150px;
}

.submit-btn {
    padding: 15px 30px;
    font-size: 1rem;
    font-weight: 600;
    background: linear-gradient(135deg, #61dafb 0%, #21a1f1 100%);
    border: none;
    border-radius: 25px;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 10px;
}

.submit-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(97, 218, 251, 0.4);
}

.submit-btn:active {
    transform: translateY(0);
}

/* Status message styling */
.status-message {
    margin-top: 20px;
    padding: 15px 20px;
    border-radius: 8px;
    font-size: 0.95rem;
    animation: slideIn 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.status-message.success {
    background-color: rgba(22, 163, 74, 0.1);
    color: #15803d;
    border: 1px solid rgba(22, 163, 74, 0.2);
}

.status-message.error {
    background-color: rgba(220, 38, 38, 0.1);
    color: #dc2626;
    border: 1px solid rgba(220, 38, 38, 0.2);
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Dark mode styles */
.contact.dark {
    color: #fff;
}

.contact.dark .form-group label {
    color: #cbd5e1;
    /* Softer label color */
}

.contact.dark .form-group input,
.contact.dark .form-group textarea {
    background-color: rgba(51, 65, 85, 0.9);
    /* Lighter dark background */
    border-color: #475569;
    /* Softer border color */
    color: #e2e8f0;
}

.contact.dark .form-group input:focus,
.contact.dark .form-group textarea:focus {
    border-color: #61dafb;
}

.contact.dark .status-message.success {
    background-color: rgba(22, 163, 74, 0.2);
    color: #4ade80;
}

.contact.dark .status-message.error {
    background-color: rgba(220, 38, 38, 0.2);
    color: #f87171;
}