.tools {
    padding: 60px 20px;
    background: none;
    color: #333;
    text-align: center;
    overflow: hidden;
}

.tools.dark {
    color: #fff;
}

.tools h2 {
    font-size: 36px;
    margin-bottom: 40px;
    position: relative;
}

.tools h2::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: #61dafb;
}

.tools-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 20px 0;
}

.tools-container::before,
.tools-container::after {
    content: '';
    position: absolute;
    top: 0;
    width: 200px;
    height: 100%;
    z-index: 2;
}

.tools-container::before {
    left: 0;
    background: linear-gradient(to right, var(--bg-color, #f5f5f5) 0%, transparent 100%);
}

.tools-container::after {
    right: 0;
    background: linear-gradient(to left, var(--bg-color, #f5f5f5) 0%, transparent 100%);
}

.tools.dark .tools-container::before {
    background: linear-gradient(to right, var(--dark-bg-color, #1f1c1c) 0%, transparent 100%);
}

.tools.dark .tools-container::after {
    background: linear-gradient(to left, var(--dark-bg-color, #1f1c1c) 0%, transparent 100%);
}

.tools-track {
    display: flex;
    animation: scroll 30s linear infinite;
    width: fit-content;
}

.tool-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;
    transition: transform 0.3s ease;
}

.tool-item:hover {
    transform: translateY(-10px);
}

.tool-item:hover .tool-icon img {
    transform: scale(1.1);
}

.tool-icon {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tool-icon img {
    width: 48px;
    height: 48px;
    object-fit: contain;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.tools.dark .tool-icon img {
    /* color: #21a1f1; */
    opacity: 0.95;
}

.tool-name {
    font-size: 0.9rem;
    font-weight: 500;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-50% - 20px));
    }
}

@media (max-width: 768px) {
    .tool-item {
        padding: 15px 30px;
    }

    .tool-icon {
        font-size: 2rem;
    }
}